import { render, staticRenderFns } from "./protoType.vue?vue&type=template&id=6633037e&scoped=true&"
import script from "./protoType.vue?vue&type=script&lang=js&"
export * from "./protoType.vue?vue&type=script&lang=js&"
import style0 from "./protoType.vue?vue&type=style&index=0&id=6633037e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6633037e",
  null
  
)

export default component.exports